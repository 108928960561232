@import "~font-awesome/css/font-awesome.css";
@import "styles/main.scss";

/* open-sans-regular - latin */
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  src: url("./fonts/open-sans-v15-latin-regular.eot"); /* IE9 Compat Modes */
  src: local("Open Sans Regular"), local("OpenSans-Regular"),
    url("./fonts/open-sans-v15-latin-regular.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("./fonts/open-sans-v15-latin-regular.woff2")
      format("woff2"),
    /* Super Modern Browsers */ url("./fonts/open-sans-v15-latin-regular.woff")
      format("woff"),
    /* Modern Browsers */ url("./fonts/open-sans-v15-latin-regular.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("./fonts/open-sans-v15-latin-regular.svg#OpenSans") format("svg"); /* Legacy iOS */
}

html,
body {
  // background-color: #F8F8F8;
  font-family: "Open Sans", sans-serif;
  height: 100%;
  padding: 0;
  margin: 0;
  overflow: hidden;
}

.hidden {
  display: none;
}

@media only screen and (max-width: 992px) {
  .hide-on-mobile {
    display: none;
  }
}

@mixin circle($width, $color) {
  width: $width !important;
  height: $width !important;
  background: $color !important;
  border-radius: $width/2;
  text-align: center;
  line-height: $width;
}

.bundles .counter {
  @include circle(20px, #c3cbcc);
  color: #fff !important;
  font-size: 10px !important;
  font-weight: bold;
}

//flexbox grid
.template_container {
  min-height: 100%;
  width: 100%;
  flex-direction: row;
}

.template_sidebar {
  width: 80px;
  flex: 0 80px;
  background: #0c2e33;
  z-index: 1301;
  position: relative;
}

.template_main {
  height: 100%;
  width: 100%;
  flex: 1;
}
