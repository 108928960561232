.popoverMenuItem {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  .fa {
    flex: 0 1 auto;
    width: 20px;
    padding-right: 10px;
    text-align: center;
  }
  .div {
    flex: 1 1 auto;
  }
}
