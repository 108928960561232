.editor-preview {
  margin-bottom: 10px;
  padding: 10px 20px 20px;
  text-align: left;
}

.editor-container {
  width: 100%;
  overflow-y: scroll;
  border: 1px solid #ccc;
  box-sizing: border-box;
  font-family: Helvetica, Arial, sans-serif;
  font-size: 13px;
  margin: 0px;
  position: relative;
}

.editor {
  line-height: 1.42;
  height: 100%;
  outline: none;
  overflow-y: auto;
  padding: 12px 15px;
  box-sizing: border-box;
  text-align: left;
  white-space: pre-wrap;
  word-wrap: break-word;
  tab-size: 4;
}

.menu.toolbar-menu {
  border: 1px solid #ccc;
  box-sizing: border-box;
  padding: 8px;
  display: flex;
  & + .editor-container {
    border-top: 0;
  }
  .button {
    box-sizing: border-box;
    background: none;
    border: none;
    cursor: pointer;
    height: 24px;
    padding: 0 2px;
    width: 28px;
    display: flex;
    .material-icons {
      display: flex;
      width: 100%;
      flex-direction: column;
      align-items: center;
    }
  }
  .formats {
    margin-right: 15px;
    display: flex;
  }
}
